<template>
    <div class="notice">
        <h2>
            Your local stored cache is cleared.
        </h2>
    </div>
</template>

<script>
export default {
    name: "ClearCache",
    metaInfo: {
        title: 'Clear Cache',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description', 
            content: "Cerda-Zein"
        }]
    },
    mounted:function(){
        this.$store.dispatch('updateProperties');
    }
}
</script>

<style lang="scss" scoped>
h2{
    text-align: center;
}
</style>
